import {mapActions, mapGetters, mapMutations} from "vuex";


import contactsSection from '@/modules/home/section/contacts/index.vue';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "flat",
  components:{
    contactsSection,
    CoolLightBox
  },
  data(){
    return{
      imageIndex: null,
    }
  },
  created() {
    this.fetchFlatData(this.$route.params.id)
  },
  computed:{
    ...mapGetters({
      flat: 'home/flat',
      flatLoading: 'home/flatLoading'
    }),
    image(){
      if(!this.flat){
        return null
      } else {
        let data = []
        data.push(this.flat.image)
        return data
      }
    }
  },

  methods:{
    ...mapActions({
      fetchFlatData: 'home/GET_FLAT'
    }),
    redirect(id){
      let url = this.$router.resolve({name: 'home', hash: `#${id}`})
      window.location.href = url.href
    }
  }
}